@import url(../../css/font.css);
.body {
  opacity: 1;
  background: transparent 0% 0% no-repeat padding-box;
}
.font-inter{
font-family: Inter;
word-wrap: break-word;
font-kerning: normal;
}
.font-neue-haas-grotesk{
    font-family: 'Neue Haas Grotesk Display 95 Black';
    word-wrap: break-word;
    font-kerning: normal;
    }
.popup-window-1 {
  width: 752px;
  height: 455px;
  /* padding: 32px;  */
  box-shadow: 0px 0px 6px #0000002e;
  border-radius: 4px;
  margin:auto;
}
.deskera-logo {
  width: 76px;
  height: 16px;
}
.new-onboarding-flow-wrapper .welcome {
  font-size: 26px !important;
}
.new-onboarding-flow-wrapper .welcome-sub-text {
  font-size: 20px !important;
  /* margin-bottom: 53px; */
}
.new-onboarding-flow-wrapper .input-fields {
  color: #3c3c3c;
  border: "1px solid #A59E8E";
}
.new-onboarding-flow-wrapper .popup-window-2 {
    width: 560px;
    height: 312px;
    box-shadow: 0px 0px 6px #0000002e;
    border-radius: 4px;
}
.new-onboarding-flow-wrapper .scale{
    font-size: 36px;
    color: #FACA60;
}
.new-onboarding-flow-wrapper .scale-sub-text{
    font-size: 16px;
    color: white;
}
.new-onboarding-flow-wrapper .input-fields input{
    color:#D8D8D8;
    font-size: 14px;
}
.new-onboarding-flow-wrapper .background{
    backdrop-filter: blur(5px);
}
.new-onboarding-flow-wrapper .dkinput-value-section .position-relative {
  position: relative;
  display: inline-block;
  /* border-bottom: 1px dotted black; */
}
.new-onboarding-flow-wrapper .dkinput-value-section .dkinput-textfield-height {
  height: 48px !important;
}
.new-onboarding-flow-wrapper .dkinput-value-section input {
  height: 48px !important;
  color: white  ;
}

.new-onboarding-flow-wrapper .onboarding-dropdown #dk-list-picker-2 {
  background-color: #585858 !important;
  border-color: #a59e8e !important;
  color: #D8D8D8 !important;
}
.new-onboarding-flow-wrapper .onboarding-dropdown #dk-list-picker-2 .listPickerBG:hover {
  background-color: #1e1d1d !important;
}
.new-onboarding-flow-wrapper .onboarding-dropdown input {
  color: black;
}
.new-onboarding-flow-wrapper .onboarding-dropdown .text-dark-gray {
  color: #D8D8D8 !important;
}
.new-onboarding-flow-wrapper .onboarding-dropdown img {
  -webkit-filter: invert(100%);
}
.new-onboarding-flow-wrapper input:-webkit-autofill,
.new-onboarding-flow-wrapper input:-webkit-autofill:hover,
.new-onboarding-flow-wrapper input:-webkit-autofill:focus,
.new-onboarding-flow-wrapper input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #3c3c3c inset !important;
  -webkit-text-fill-color: #dbdbdb;
  background-color: #3c3c3c;
  border-radius: 5px;
}
.new-onboarding-flow-wrapper .input-fields .has-Error .text-red{
    background-color: #AC2E00;
    border: 1px solid #F0124D;
    color:white;
    padding:"14px 113px 7px 32px";
    width:230px;
    height: 37px;
}

.new-onboarding-flow-wrapper .dkinput-value-section .position-relative .text-red {
  display: flex;
  align-items: center;
  visibility: visible;
  width: max-content;
  background-color: #AC2E00;
  color: #fff;
  text-align: left;
  border-radius: 4px;
  padding: 0px 5px;
  position: absolute;
  width:auto;
  height:18px;
  border: 1px solid #F0124D;
  z-index: 1;
  top: 150%;
  left: 0;
  margin-left: 0px;
}

.new-onboarding-flow-wrapper .dkinput-value-section .position-relative .text-red::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 15px;
  padding-top: 3px;
  margin-left: -5px;
  border-width: 6px;
  border-style: solid;
  border-color: transparent transparent #AC2E00 transparent;
}

.new-onboarding-flow-wrapper .dkinput-value-section .position-relative .text-red::before {
  content: '\26A0';
  padding-right: 5px;
  font-size: 13px;
}

.new-onboarding-flow-wrapper .onboarding-dropdown .position-relative .text-red::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 15px;
  margin-left: -5px;
  border-width: 6px;
  border-style: solid;
  border-color: transparent transparent #AC2E00 transparent;
}
.new-onboarding-flow-wrapper .onboarding-dropdown .position-relative .text-red::before {
  content: '\26A0';
  padding-right: 4px;
  font-size: 13px;
}
.new-onboarding-flow-wrapper .has-Error .onboarding-dropdown .text-red{
  display: flex;
  align-items: center;
  background-color: #AC2E00;
  border: 1px solid #F0124D;
  color:white;
  border-radius: 4px;
  padding:0px 5px;
  width:fit-content;
  height: 18px !important;
}
.new-onboarding-flow-wrapper .onboarding-dropdown #dk-list-picker-2{
  top:0 !important;
  bottom: auto !important;
}
