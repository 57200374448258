::-webkit-scrollbar {
  width: 5px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #b4b4b4 !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #686868 !important;
}

.main-holder {
  display: flex;
  /* overflow-y: hidden; */
  align-items: flex-start;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  position: relative !important;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100%;
  text-align: center;
  font-size: 13px;
  /* overflow-x: hidden; */
  font-family: -apple-system, BlinkMacSystemFont, "Arial", "Roboto",
    "Helvetica Neue", sans-serif;
}

.transparent-background {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 9997;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

.transparent-background-light {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 9997;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
}

.popup-window {
  position: absolute;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 9998;
  overflow-y: scroll;
  box-sizing: border-box;
  padding: 20px;
  max-width: 600px;
  max-height: 90%;
  width: 90%;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 20, 0.05);
  transform: translate(-50%, -50%);

  scrollbar-width: none;
  -ms-overflow-style: none;
}

.company-logo {
  margin-left: 20px;
  height: 30px;
}

.screen-width {
  width: 100vw;
}

.screen-height {
  height: 100vh;
}

.parent-width {
  width: 100%;
}

.parent-height {
  height: 100%;
}

.parent-size {
  width: 100%;
  height: 100%;
}

.row {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  box-sizing: border-box;
  width: 100%;
}

.row-reverse {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  box-sizing: border-box;
  width: 100%;
}

.column {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
}

.row-responsive {
  flex-wrap: nowrap;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-1 {
  flex: 1;
}

/* ///// */

.headerCouponButton {
  overflow: hidden;
}

.headerMenuButton {
  overflow: hidden;
  width: 0px;
}

.menu {
  min-width: 230px;
  width: 320px;
  height: 100%;
  padding: 20px;
}

.menuFull {
  width: 100%;
  height: 100%;
  padding: 20px;
}

.mainBody {
  overflow-x: scroll;
  justify-content: flex-start;
  padding: 30px;
  width: 100%;
  height: 100%;
  background-color: white;
}

.mainBodyHidden {
  overflow: hidden;
  padding: 0px;
  width: 0%;
  height: 0%;
}

/* ///// */

.z-index-1 {
  z-index: 1;
}

.z-index-2 {
  z-index: 2;
}

.z-index-3 {
  z-index: 3;
}

.z-index-4 {
  z-index: 4;
}

.z-index-5 {
  z-index: 5;
}

/* ///// */

.button-justify {
  display: flex;
  flex-direction: row;
  width: 100%;
  border: 1px solid rgb(220, 220, 220);
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
}

.focusElement:focus {
  border-color: rgb(220, 220, 220) !important;
}

/* ///// */

.border-box {
  box-sizing: border-box;
}

.display-block {
  display: inline-block;
}

.display-none {
  display: none;
}

.display-only-web {
  display: flex;
}

.display-only-mobile {
  display: none;
}

.scroll-y-only-web {
  overflow-y: scroll;
}

.cursor-hand {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.cursor-move {
  cursor: move;
}

.justify-content-around {
  justify-content: space-around;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-between {
  justify-content: space-between;
}

.align-items-center {
  align-items: center;
}

.align-items-baseline {
  align-items: baseline;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-end {
  align-items: flex-end;
}

.align-items-stretch {
  align-items: stretch;
}

.align-self-center {
  align-self: center;
}

.align-self-start {
  align-self: flex-start;
}

.align-self-end {
  align-self: flex-end;
}

.justify-self-center {
  justify-self: center;
}

.justify-self-start {
  justify-self: flex-start;
}

.justify-self-end {
  justify-self: flex-end;
}

.ic-r {
  height: 24px;
}

.ic-xs {
  height: 11px;
}

.ic-xs-2 {
  height: 14px;
}

.ic-s {
  height: 16px;
}

.ic-s-2 {
  height: 20px;
}

.ic-m {
  height: 35px;
}

.ic-r-2 {
  width: 27px;
  height: 27px;
}

.ic-r-3 {
  width: 30px;
  height: 30px;
}

.ic-l {
  height: 48px;
}

.ic-xl {
  height: 70px;
}

/* BORDER */
.border-none {
  border: none;
}

.border-s {
  border: solid;
  border-width: 1px;
  border-color: rgb(240, 240, 240);
}

.border-m {
  border: solid;
  border-width: 1px;
  border-color: rgb(220, 220, 220);
}

.border-red {
  border: solid;
  border-width: 1px;
  border-color: rgba(201, 34, 45, 0.527);
}

.border-green {
  border: solid;
  border-width: 1px;
  border-color: rgb(0, 121, 91);
}

.border-orange {
  border: solid;
  border-width: 1px;
  border-color: rgb(215, 70, 30);
}

.border-blue {
  border: solid;
  border-width: 1px;
  border-color: rgb(22, 100, 215);
}

.border-purple {
  border: solid;
  border-width: 1px;
  border-color: #2C1F47;
}

/* BORDER RADIUS */

.border-radius-s {
  border-radius: 4px;
}

.border-radius-m {
  border-radius: 8px;
}

.border-radius-r {
  border-radius: 12px;
}

.border-radius-l {
  border-radius: 20px;
}

.border-radius-xxl {
  border-radius: 35px;
}

.border-radius-none {
  border-radius: 0px;
}

/* SHADOW */
.shadow-s {
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.05);
}

.shadow-m {
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
}

.shadow-red-m {
  box-shadow: 0px 0px 5px rgba(255, 0, 0, 0.7);
}

.text-gray {
  color: gray;
}

.text-dark-gray {
  color: #505050
}

.text-white {
  color: white;
}

.text-royalblue {
  color: royalblue;
}

.text-green {
  color: rgb(0, 121, 91);
}

.text-orange {
  color: rgb(215, 70, 30);
}

.text-blue {
  color: rgb(22, 100, 215);
}

.text-red {
  color: rgb(203, 26, 38);
}

.text-cut {
  text-decoration: line-through;
}

.text-line-through {
  text-decoration: line-through;
}

.text-purple {
  color: #2C1F47;
}


/* BG COLOR */
.bg-menu-go {
  background-color: rgb(203, 26, 38) !important;
}

.bg-app {
  background-color: rgb(203, 26, 38) !important;
}

.bg-dark-gray {
  background-color: rgb(30, 30, 30);
}

.bg-gray {
  background-color: gray;
}

.bg-gray1 {
  background-color: #f4f4f6;
}

.bg-gray2 {
  background-color: #e9ebed;
}

.bg-gray3 {
  background-color: #dfe1e2;
}

.bg-gray4 {
  background-color: #cccfd1;
}

.bg-gray5 {
  background-color: #b7bfc6;
}

.bg-line {
  background-color: rgb(242, 242, 242);
}

.bg-line-2 {
  background-color: rgb(222, 222, 222);
}

.bg-red {
  background-color: rgb(203, 26, 38);
}

.bg-yellow {
  background-color: yellow;
}

.bg-green {
  background-color: green;
}

.bg-white {
  background-color: white;
}

.bg-blue {
  background-color: rgb(28, 115, 232);
}

.bg-orange {
  background-color: rgb(215, 70, 30);
}

.bg-purple {
  background-color: #2C1F47;
}

.bg-mobile {
  background-color: #F2F2F2;
}

.bg-transparent {
  background-color: transparent;
}

.bg-deskera {
  background-color: rgb(255, 198, 74);
}

.bg-erp {
  background-color: rgb(57, 87, 213);
}

.bg-crm {
  background-color: rgb(26, 175, 93);
}

.bg-people {
  background-color: rgb(240, 126, 37);
}

.bg-aio {
  background-color: rgb(8, 18, 90);
}

.bg-deskera-secondary {
  background-color: rgb(254, 246, 225);
}

.bg-erp-secondary {
  background-color: rgba(241, 243, 255, 0.5);
}

.bg-crm-secondary {
  background-color: rgb(230, 247, 238);
}

.bg-people-secondary {
  background-color: rgb(254, 247, 242);
}

.bg-aio-secondary {
  background-color: rgb(232, 235, 255);
}

.bg-chip-green {
  background-color: rgb(213, 232, 226);
}

.bg-chip-blue {
  background-color: rgb(210, 228, 249);
}

.bg-chip-red {
  background-color: rgb(255, 212, 212);
}

.bg-chip-orange {
  background-color: rgb(254, 240, 225);
}

/* PADDING */

.pl-r {
  padding-left: 15px;
}

.pr-r {
  padding-right: 15px;
}

.pt-r {
  padding-top: 15px;
}

.pb-r {
  padding-bottom: 15px;
}

.pl-s {
  padding-left: 8px;
}

.pr-s {
  padding-right: 8px;
}

.pr-xs {
  padding-right: 4px;
}

.pt-s {
  padding-top: 8px;
}

.pt-xs {
  padding-top: 4px;
}

.pb-s {
  padding-bottom: 8px;
}

.pb-xs {
  padding-bottom: 4px;
}

.p-m {
  padding: 12px;
}

.p-s {
  padding: 8px;
}

.p-xs {
  padding: 6px;
}

.pl-l {
  padding-left: 32px;
}

.pr-l {
  padding-right: 32px;
}

.pt-l {
  padding-top: 32px;
}

.pb-l {
  padding-bottom: 32px;
}

.pl-m {
  padding-left: 12px;
}

.pr-m {
  padding-right: 12px;
}

.pt-m {
  padding-top: 12px;
}

.pb-m {
  padding-bottom: 12px;
}

.p-v-r {
  padding-top: 16px;
  padding-bottom: 16px;

}

.m-m {
  margin: 12px;
}

.ml-r {
  margin-left: 8px;
}

.ml-xs {
  margin-left: 4px;
}

.ml-s {
  margin-left: 6px;
}

.ml-m {
  margin-left: 12px;
}

.ml-l {
  margin-left: 18px;
}

.ml-xl {
  margin-left: 24px;
}

.ml-xxl {
  margin-left: 32px;
}

.mt-r {
  margin-top: 8px;
}

.mt-xs {
  margin-top: 3px;
}

.mt-s {
  margin-top: 6px;
}

.mt-m {
  margin-top: 12px;
}

.mt-l {
  margin-top: 18px;
}

.mt-xl {
  margin-top: 24px;
}

.mt-xxl {
  margin-top: 32px;
}

.mr-r {
  margin-right: 8px;
}

.mr-s {
  margin-right: 6px;
}

.mr-m {
  margin-right: 12px;
}

.mr-l {
  margin-right: 18px;
}

.mb-r {
  margin-bottom: 8px;
}

.mb-s {
  margin-bottom: 6px;
}

.mb-m {
  margin-bottom: 12px;
}

.mb-l {
  margin-bottom: 16px;
}

.mb-xl {
  margin-bottom: 28px;
}

/* FONT SIZE */

.fs-r {
  font-size: 13px;
}

.fs-xs {
  font-size: 8px;
}

.fs-s {
  font-size: 10px;
}

.fs-m {
  font-size: 14px;
}

.fs-l {
  font-size: 16px;
}

.fs-xl {
  font-size: 20px;
}

.fs-xl-2 {
  font-size: 22px;
}

.fs-xxl {
  font-size: 24px;
}

.fs-xxxl {
  font-size: 30px;
}

.circle {
  border-radius: 50%;
}

.ic-contact-r {
  width: 30px;
  height: 30px;
}

/* FONT WEIGHT */

.fw-l {
  font-weight: lighter;
}

.fw-r {
  font-weight: normal;
}

.fw-m {
  font-weight: 600;
}

.fw-4 {
  font-weight: 400;
}

.fw-b {
  font-weight: bold;
}

.fw-h {
  font-weight: bolder;
}

/* TEXT ALIGNMENT */

.text-wrap-none {
  white-space: nowrap;
}

.text-wrap {
  word-wrap: break-word;
}

.text-trailing {
  text-overflow: ellipsis;
}

.text-align-left {
  text-align: left;
}

.text-align-center {
  text-align: center;
}

.text-align-right {
  text-align: right;
}

.text-align-justify {
  text-align: justify;
}

/* TEXTFIELD */
.textField {
  padding: 10px;
  border: solid;
  border-width: 1px;
  border-color: rgb(220, 220, 220);
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  width: 100%;
  background-color: #f4f4f6;
  box-sizing: border-box;
}

.textField:focus {
  /* border-width: 0px; */
  border-color: orange;
  outline: none !important;
  /* box-shadow: 0 0 3px orange; */
}

.textField-warning {
  border-color: red;
  border-width: 1px;
  background-color: rgba(255, 0, 0, 0.05);
}

.textField-invalid-text {
  color: red;
  margin-left: 4px;
  /* position: absolute;
  top: 45px;
  left: 4px; */
  font-size: 12px;
}

.TextFieldFilter {
  width: 100%;
  outline-width: 0;
  padding: 6px;
  outline-style: solid;
  border: 1px solid #e9ebed;
  border-radius: 4px;
  text-align: center;
  background-color: transparent;
  font-size: 13px;
}

.TextFieldFilterError {
  width: 100%;
  outline-width: 0;
  padding: 6px;
  outline-style: solid;
  border: 1px solid red;
  border-radius: 4px;
  text-align: center;
  background-color: transparent;
  font-size: 13px;
}

.textarea-resize-none {
  resize: none;
}

.textarea-resize-outline {
  overflow: auto;
  outline: none;
  border: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.listPickerBG {
  background-color: transparent;
}

.listPickerBG:hover {
  background-color: #f4f4f6;
}

::-webkit-scrollbar {
  width: 0px;
  /* Remove scrollbar space */
  background: transparent;
  /* Optional: just make scrollbar invisible */
}

.navigation-title {
  cursor: pointer;
  font-weight: bold;
  font-size: 20px;
  color: "#202124";
}

.navigation-title:hover {
  color: #0645ad;
}

.price-section {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  grid-gap: 1.4rem;
  /* padding: 1rem;
  margin: 1rem;  */
  padding: 0px;
  margin: 0px;
  margin-top: 10px;
}

.hide-scroll-bar {
  scrollbar-width: none;
}

.right-section-1 {
  width: 350px;
}

.right-section-2 {
  width: 420px;
}

.dashboard-award {
  height: 110px;
  max-width: 400px;
  width: 100%;
}

.pr-r-only-web {
  padding-right: 15px;
}

.ml-l-only-web {
  margin-left: 18px;
}

.width-80 {
  width: 80%;
}

.main-holder-p-horizontal {
  padding-left: 32px;
  padding-right: 32px;
}

.main-holder-p-vertical {
  padding-top: 32px;
  padding-bottom: 32px;
}

.blur {
  filter: blur(5px);
  opacity: 0.5;
}

.unselectable {
  user-select: none;
}

.align-web-items-center {
  align-items: center;
}

.mobile-parent-width {
  width: 150px
}

.mobile-pt-s {
  padding-top: 10px !important;
}

.overflow-x-auto {
  overflow-x: auto;
}

.chart-width {
  width: 33.3%;
}

.banner-mobile {
  width: 25%;
}

.width-50 {
  width: 50%;
}

.width-m-100 {
  width: auto;
}

.user-email {
  width: 280px;
}

@media only screen and (max-width:1300px) {
  .user-email {
    width: 170px;
  }
}

@media only screen and (max-width:570px) {
  .user-email {
    width: fit-content;
  }
}

@media only screen and (min-width : 801px) and (max-width:1200px) {
  .chart-width {
    width: 50%;
  }
}

@media only screen and (max-width: 800px) {
  .width-50 {
    width: auto;
  }

  .width-m-100 {
    width: 100% !important;
  }

  .banner-mobile {
    width: 100%;
    margin-left: 6px;
  }

  .chart-width {
    width: 100%;
  }

  .row-responsive {
    flex-wrap: wrap;
  }

  .right-section-1 {
    width: 100%;
  }

  .right-section-2 {
    width: 100%;
  }

  .dashboard-award {
    width: 100%;
    height: auto;
  }

  .pr-r-only-web {
    padding-right: 0px;
  }

  .ml-l-only-web {
    margin-left: 0px;
  }

  .width-80 {
    width: 100%;
  }

  .main-holder-p-horizontal {
    padding-left: 14px;
    padding-right: 14px;
  }

  .main-holder-p-vertical {
    padding-top: 14px;
    padding-bottom: 14px;
  }

  .menu {
    min-width: 0px;
    width: 0%;
    padding: 0px;
  }

  .headerMenuButton {
    margin-right: 5px;
    width: 35px;
  }

  .headerCouponButton {
    overflow: hidden;
    width: 0px;
  }

  .padding-empty-mobile {
    padding: 0 !important;
  }

  .padding-top-mobile {
    padding-top: 12px !important;
  }

  .align-web-items-center {
    align-items: flex-start;
  }

  .display-only-web {
    display: none;
  }

  .display-only-mobile {
    display: flex;
  }

  .scroll-y-only-web {
    overflow-y: visible;
  }

  .mobile-parent-width {
    width: 100%;
  }

  .mobile-width-70 {
    width: 70% !important;
  }

  .mobile-width-90 {
    width: 90% !important;
  }

  .mobile-width-85 {
    width: 85% !important;
  }

  .dk-sidebar-toggle.expandable-button {
    visibility: visible;
  }

}

@media (prefers-reduced-motion: no-preference) {
  .Loading-logo {
    animation: Loading-logo-spin infinite 3s linear;
  }
}

@keyframes Loading-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.Rotate {
  position: absolute;
  transform: rotate(0.75turn);
}

.fontStyle {
  white-space: pre-wrap;
  font-size: 13px;
  text-align: left;
  word-break: break-all;
}

.fontStyleBold {
  font-size: 13px;
  font-weight: bold;
  text-align: left;
  word-break: break-all;
}

.colorpicker {
  border-radius: 6px;
  border: 1px solid rgb(200, 200, 200);
  padding: 1px 3px;
}

.documentListRow {
  background-color: white;
}

.documentListRow:hover {
  background-color: rgba(244, 244, 244, 0.7);
}

.position-absolute {
  position: absolute;
}

.position-relative {
  position: relative;
}

.under-line:hover {
  text-decoration: underline;
}

/* ================================================================ */
/* to override adyen card css */
.adyen-checkout__payment-method {
  /* Payment method container */
}

.adyen-checkout__payment-method--selected {
  /* Payment method that has been selected */
  background: white !important;
  border: 0 !important;
}

.adyen-checkout__payment-method__header {
  /* Payment method icon and name */
  display: none !important;
}

.adyen-checkout__payment-method__details {
  margin-top: 15px;
  padding: 0 !important;
}

.adyen-checkout__payment-method__image {
  display: none !important;
}

.adyen-checkout__payment-method__image__wrapper--outline::after {
  /* background-image: url("../assets/icons/ic_cards_1.png");
  opacity: 0.5;
  border: 0 !important;
  background-repeat: no-repeat !important; */
  border: 0 !important;
}

.DropinComponent-module_adyen-checkout__payment-method__image__wrapper__pTTKr {
  margin-right: 0 !important;
}

.adyen-checkout__payment-method__radio {
  /* Radio button in payment method header */
}

.adyen-checkout__payment-method__radio--selected {
  /* Selected radio button in payment method header */
}

.adyen-checkout__payment-method__name {
  /* Payment method name in the payment method header */
  font-weight: 600 !important;
  font-size: 14px !important;
}

.adyen-checkout__spinner__wrapper {
  /* Spinning icon */
}

.adyen-checkout__button {
  /* Buttons */
}

.adyen-checkout__button--pay {
  /* Pay button */
  background-color: rgb(28, 115, 232) !important;
  display: flex !important;
  align-items: center !important;
  box-sizing: border-box !important;
  width: auto !important;
  flex-direction: row !important;
  justify-content: flex-start !important;
  height: 30px !important;
}

.adyen-checkout__field {
  /* Form field container */
}

.adyen-checkout__label {
  /* Form label container */
}

.adyen-checkout__label__text {
  /* Text element inside the form label container */
  display: none !important;
}

.adyen-checkout__input {
  /* Input fields */
  background-color: #f4f4f6 !important;
}

.adyen-checkout__spinner,
.adyen-checkout__spinner--large {
  /* display: none !important; */
}

.adyen-checkout__input--error {
  /* Error state for the input fields */
}

.adyen-checkout__error-text {
  /* Error message text */
}

.adyen-checkout__card__cardNumber__input {
  /* Input field for the card number */
}

.adyen-checkout__field--expiryDate {
  /* Input field for the expiry date */
}

.adyen-checkout__field__cvc {
  /* Input field for the CVC security code */
}

.adyen-checkout__card__holderName {
  /* Input field for card holder name */
}

.adyen-checkout__checkbox__input {
  /* Checkboxes */
}

.adyen-checkout__checkbox__label {
  /* Checkbox labels */
}

.adyen-checkout__radio_group__input {
  /* Radio buttons */
}

.adyen-checkout__dropdown__button {
  /* Dropdown button showing list of options */
}

.adyen-checkout__dropdown__list {
  /* Dropdown list */
}

.adyen-checkout__dropdown__element {
  /* Elements in the dropdown list */
}

.adyen-checkout__link {
  /* Links */
}

.bottom-sheet-popup-window {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 9998;
  margin-right: 2px;
  margin-left: 2px;
  box-sizing: border-box;
  padding-bottom: 10px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 20, 0.05);

  scrollbar-width: none;
  -ms-overflow-style: none;
}

.width-auto {
  width: auto;
}

.border-radius-left {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.border-radius-right {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

/* ================================================================ */

.content-holder .hide-block {
  visibility: hidden;
}

.content-holder:hover .hide-block {
  visibility: visible;
}

/* ================================================================ */

.bg-button,
div.dk-bg-button,
button.dk-bg-button {
  background-color: rgb(203, 26, 38);
}

input[placeholder="OTP Code *"] {
  background-color: white !important;
}

#tooltip {
  position: absolute;
  left: 0px;
  top: 0px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.42857143;
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  font-size: 12px;
  display: inline-block;
}

#tooltip.right {
  margin-left: 5px;
}

#tooltip.left {
  margin-left: -5px;
}

#tooltip.top {
  margin-top: -5px;
}

#tooltip.bottom {
  margin-top: 5px;
}

#tooltip .tooltip-arrow {
  top: 50%;
  left: 0;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #000;
}

#tooltip.right .tooltip-arrow {
  top: 50%;
  left: auto;
  margin-left: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #000;
}

#tooltip.bottom .tooltip-arrow {
  top: auto;
  bottom: -5px;
  left: 50%;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: #fff;
}

#tooltip.left .tooltip-arrow {
  top: 50%;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
  border-left-color: #fff;
  right: -5px;
  left: auto;
}

#tooltip.top .tooltip-arrow {
  top: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #fff;
}

#tooltip .tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-right-color: transparent;
  border-style: solid;
}

#tooltip .tooltip-label {
  max-width: 600px;
  padding: 3px 8px;
  color: black;
  text-align: center;
  background-color: #fff;
  border-radius: 4px;
}

.welcome-message {
  white-space: nowrap !important;
  overflow: hidden;
  text-overflow: ellipsis;
}

.contain-wrapper-web {
  width: calc(100% - 230px);
  left: 230px;
  position: relative;
  right: 0px;
  margin-top: 59px !important;
}
.dk-sidebar{
  position: fixed !important;
}
.header-wrapper {
  left: 230px;
          right: 0px;
          width: calc(100% - 230px) !important;
          position: fixed;
}
@media only screen and (max-width:1000px){
  .contain-wrapper-web {
   width: 100% !important;
   left: 0px;
  position: relative;
  right: 0px;
  }
  .dk-sidebar{
    position: fixed !important; 
  }
  .header-wrapper {
    width: 100% !important;
    left: 0px;
  }
}