.pricing-preview-subtitle{
    font-weight: 700;
    font-size: 34px !important;
    line-height: 40px;
    letter-spacing: -0.01em;
}
.pricing-preview .v2-grid-container {
    gap: 16px 2%;
    overflow: hidden hidden;
}
.pricing-preview .gradient-wrapper .pricing-image {
    margin-top: 30px !important;
}
.pricing-preview-subtitle{
    font-weight: 600;
    font-size: 2em !important;
    line-height: 1.2;
}
.pricing-preview > .row-responsive {
    flex-wrap: wrap;
    justify-content: center;
}
.pricing-preview > .row-responsive {
    flex-wrap: wrap;
    justify-content: center;
}
.pricing-preview .pricing-preview-card {
    max-width: 350px;
}
.pricing-preview-plan-name,
.banner-title {
    font-weight: 900;
    font-size: 36px !important;
    line-height: 40px;
}
.pricing-preview-plan-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 125%;
    color: #181818;
}
.pricing-preview-price {
    font-size: 17px;
    line-height: 130%;
    display: flex;
    align-items: center;
    letter-spacing: -0.008em;
    color: #0f172a;
}
.pricing-preview .text-green {
    color: #1aaf5d;
}
.pricing-preview-cta {
    font-size: 14px;
    background: #181818;
    font-weight: 600;
    border-radius: 8px;
    border: none;
    line-height: 20px;
}

.pricing-preview-cta:hover {
    background: #f7cf6a;
    border: 1px solid #d2b058;
    border-radius: 8px;
}
@media screen and (max-width: 800px) {
    .pricing-preview-card {
        height: 100%;
    }
    .enterpise-blank-section {
        height: 98px;
    }
}

.pricing-preview-card {
    padding: 15px;
    border-radius: 21px;
}
.mix-blend-border {
    position: relative;
}
.mix-blend-border:hover {
    -webkit-transform: scale(1.01);
    transform: scale(1.01);
    transition: -webkit-transform .2s ease;
    transition: transform .2s ease;
    transition: transform .2s ease,-webkit-transform .2s ease;
}
.mix-blend-border:before {
    border: 2px solid #000;
    mix-blend-mode: overlay;
    content: "";
    position: absolute;
    top: 1px;
    bottom: 1px;
    left: 1px;
    right: 1px;
    border-radius: inherit;
}
.pricing-preview-card:hover {
    box-shadow: 0 20px 25px -3px rgba(0,0,0,.1)!important;
}
@media only screen and (min-width: 801px) and (max-width: 1260px) {
    .mobile-height-auto {
        height: auto !important;
    }

    .mobile-parent-width {
        width: 100% !important;
    }

    .mobile-width-90 {
        width: 90vw !important;
    }

    .mobile-justify-content-center {
        justify-content: center !important;
    }

    .mobile-text-center {
        text-align: center !important;
    }

    .mobile-align-items-start {
        align-items: flex-start !important;
    }

    .mobile-align-items-center {
        align-items: center !important;
    }

    .pricing-preview > .row-responsive {
        flex-wrap: wrap;
        justify-content: center;
    }

    .pricing-preview .pricing-preview-card {
        max-width: 350px;
    }

    .plan-wrapper {
        max-width: 65vw;
        display: flex;
        display: flex;
        flex-wrap: nowrap;
    }

    .pricing-card-mobile-wrapper,
    .price-card-mobile-footer {
        max-width: 65vw;
        display: block;
        display: block;
    }

    .pricing-card-wrapper,
    .price-card-footer {
        max-width: 65vw;
        display: none;
        display: none;
    }
}
.business-function-cards.inactive {
    background: transparent !important;
    border: none !important;
}

.business-function-cards.inactive .header-chip {
    display: none;
}

.business-function-cards.inactive .category-chip {
    background: transparent !important;
}

.business-function-cards:not(.inactive) .category-chip {
    color: transparent !important;
}

.business-function-cards.inactive::before {
    background: transparent !important;
}

.business-function-cards.gradient-border::before {
    padding: 3px 3px 0px 3px !important;
}

.gradient-border-blue-purple::before {
    background: linear-gradient(135deg, #4776e6, #8e54e9);
}